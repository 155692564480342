import React from 'react'
import Logo from '../../assets/images/logo.png'
import { Button } from 'antd'
import './index.scss';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <div>
            <div className="header_main_container">
                <div className="header_container border_box_container">
                    <div className="logo_container">
                        <Link to='/'>
                            {/* <img src={Logo} loading='lazy' alt="Logo" /> */}
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/2f1de2566d926081aad4a2e6b12fb6d5ec9b14560291406ab8f2cf267896ec3c?apiKey=0d50848586c3436880ef47e44d650c56&"
                                alt="Company Logo"
                                className="company-logo"
                            />
                        </Link>
                    </div>
                    <div className="links_container">
                        <Button type='primary'>Launch</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header