import React from 'react'
import './index.scss';

const Footer = () => {

  function Logo() {
    return (
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/2f1de2566d926081aad4a2e6b12fb6d5ec9b14560291406ab8f2cf267896ec3c?apiKey=0d50848586c3436880ef47e44d650c56&"
        alt="Company Logo"
        className="company-logo"
      />
    );
  }

  function Description() {
    return (
      <p className="description">
        With lots of unique blocks, you can easily build a page without coding.
        Build your next landing page.
      </p>
    );
  }

  function SocialIcons() {
    return (
      <div className="social-icons">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a1cd72311ea22561a407f9bfec7293328d00809530b7e73b6107183664f50df1?apiKey=0d50848586c3436880ef47e44d650c56&"
          alt="Social media icons"
          className="social-icons-img"
        />
      </div>
    );
  }

  const footerLinks = [
    {
      title: "Company",
      links: ["About us", "Contact us", "Careers", "Press"],
    },
    {
      title: "Product",
      links: ["Features", "Pricing", "FAQ", "Download"],
    },
    {
      title: "Legal",
      links: ["Terms of Service", "Privacy Policy", "Cookie Policy", "Disclaimer"],
    },
    {
      title: "Follow",
      links: ["Twitter", "LinkedIn", "Facebook", "Instagram"],
    },
  ];

  function FooterColumn({ title, links }) {
    return (
      <div className="footer-column">
        <h3 className="footer-column-title">{title}</h3>
        <ul className="footer-column-list">
          {links.map((link, index) => (
            <li key={index} className="footer-column-item">
              {link}
            </li>
          ))}
        </ul>
      </div>
    );
  }


  return (
    <div>
      <div className="footer_main_container">
        <div className="footer_container border_box_container">


          <footer className="footer">
            <div className="footer-content">
              <div className="footer-info">
                <Logo />
                <Description />
                <SocialIcons />
              </div>
              <div className="footer-links">
                {footerLinks.map((column, index) => (
                  <FooterColumn key={index} title={column.title} links={column.links} />
                ))}
              </div>
            </div>
            <hr className="footer-divider" />
            <div className="footer-copyright">
              © 2024 Copyright, All Right Reserved.
            </div>
          </footer>

        </div>
      </div>
    </div>
  )
}

export default Footer