import Router from "./Router";
import Header from "./components/Header";
import HomePage from "./containers/HomePage";

function App() {
  return (
    <div className="App">
      <Header />
      <HomePage />
      {/* <Router /> */}
    </div>
  );
}

export default App;