import React from 'react'
import ProductMainImage from '../../assets/images/hero_poster.png'
import App1 from '../../assets/images/app1.png';
import App2 from '../../assets/images/app2.png';
import FinanceShipImage from '../../assets/images/finance_ship.png'
import './index.scss';
import { Button, Card } from 'antd';
import { product1Logo, product2Logo, tokenizationIcon } from '../../utils/icon';
import HalfCircle from '../../assets/images/half_circle.png'
import Footer from '../../components/Footer';

const HomePage = () => {


    const articles = [
        {
            id: 1,
            date: "March 10, 2023",
            title: "COMDEX: Bridging DeFi and CeFi",
            description:
                "In the same way that technology has digitized the trade of equities or forex and has made these assets accessible to the common investor...",
            image: "https://cdn.builder.io/api/v1/image/assets/TEMP/ef8b223666d58d278673f58483d3544b7c83caed55383184ef0ea8b577f1aa2e?apiKey=0d50848586c3436880ef47e44d650c56&",
        },
        {
            id: 2,
            date: "March 10, 2023",
            title: "COMDEX: Bridging DeFi and CeFi",
            description:
                "In the same way that technology has digitized the trade of equities or forex and has made these assets accessible to the common investor...",
            image: "https://cdn.builder.io/api/v1/image/assets/TEMP/ef8b223666d58d278673f58483d3544b7c83caed55383184ef0ea8b577f1aa2e?apiKey=0d50848586c3436880ef47e44d650c56&",
        },
        {
            id: 3,
            date: "March 10, 2023",
            title: "COMDEX: Bridging DeFi and CeFi",
            description:
                "In the same way that technology has digitized the trade of equities or forex and has made these assets accessible to the common investor...",
            image: "https://cdn.builder.io/api/v1/image/assets/TEMP/ef8b223666d58d278673f58483d3544b7c83caed55383184ef0ea8b577f1aa2e?apiKey=0d50848586c3436880ef47e44d650c56&",
        },
    ];

    const ArticleCard = ({ article }) => {
        return (
            <div className="article-card">
                <img src={article.image} alt="Article" className="article-image" />
                <div className="article-content">
                    <div className="article-date">{article.date}</div>
                    <h3 className="article-title">{article.title}</h3>
                    <p className="article-description">{article.description}</p>
                    <div className="learn-more-link">
                        <span>Learn More</span>
                        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/d981efeb32d22f3f1c0bdbf035926af8d36dca12bb6a3c3f558bd2af980433a1?apiKey=0d50848586c3436880ef47e44d650c56&" alt="" className="arrow-icon" />
                    </div>
                </div>
            </div>
        );
    };



    return (
        <div>
            <div className="homepage_main_container">
                <div className="homepage_container ">

                    {/* Hero section  */}

                    <div className="hero_bg_container">
                        <div className="hero_content_container border_box_container">
                            <div className="hero_title">Elevating Onchain Markets with ShipFi</div>
                            <div className="hero_para"> Where Efficiency Meets Institutional-Grade Transparency</div>
                            <div className="hero_btn_container"><Button type='primary'>Our Features</Button></div>
                        </div>
                        <div className="hero_poster_container">
                            <img src={ProductMainImage} alt="Image" />
                        </div>
                    </div>


                    {/* Tokenization Container  */}

                    <div className="tokenization_main_container">
                        <div className="tokenization_container border_box_container">
                            <div className="tokenization_heading default_title">The Tokenization Advantage</div>
                            <div className="tokenization_boxes_container">
                                <div className="tokenization_boxes">
                                    <div className="icon">
                                        <div dangerouslySetInnerHTML={{ __html: tokenizationIcon }} />
                                    </div>
                                    <div className="text_container">
                                        <div className="text">Tailored Lending</div>
                                        <div className="para">Access a curated selection of lending options designed to meet your specific needs for liquidity, risk tolerance, and returns.</div>
                                    </div>
                                </div>
                                <div className="tokenization_boxes">
                                    <div className="icon">
                                        <div dangerouslySetInnerHTML={{ __html: tokenizationIcon }} />
                                    </div>
                                    <div className="text_container">
                                        <div className="text">Frictionless Borrowing</div>
                                        <div className="para">Unlock the value of your real-world assets by tapping into affordable DeFi capital, bypassing third parties and intermediaries.</div>
                                    </div>
                                </div>
                                <div className="tokenization_boxes">
                                    <div className="icon">
                                        <div dangerouslySetInnerHTML={{ __html: tokenizationIcon }} />
                                    </div>
                                    <div className="text_container">
                                        <div className="text">Streamlined Payments</div>
                                        <div className="para">Simplify your cross-border transactions with Shipfi's user-friendly payment and invoicing solutions.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Credit Financing  Container  */}

                    <div className="credit_financing_main_container">
                        <div className="credit_financing_container border_box_container">
                            <div className="credit_financing_heading default_title">Credit Financing </div>
                            <div className="credit_financing_para default_value">ShipFi aims to tap into over <span>$1.5 trillion</span>  of credit financing market volumes on-chain. The platform will generate revenue from</div>

                            <div className="credit_financing_contente_container">
                                <div className="left_container">
                                    <img src={FinanceShipImage} alt="Image" />
                                </div>
                                <div className="right_container">
                                    <div className="text_container">
                                        <div className="text">Settlement fees (%)</div>
                                        <div className="text">New loan financing fees (%)</div>
                                        <div className="text">Investment/Redemption fees ($)</div>
                                        <div className="text">Repayment fees ($)</div>
                                        <div className="text">Underwriter fees ($)</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="circle_left_icon circle_right_icon_1">
                            <img src={HalfCircle} alt="Image" />
                        </div>
                        <div className="circle_right_icon circle_right_icon_2">
                            <img src={HalfCircle} alt="Image" />
                        </div>
                    </div>

                    {/* Our Apps */}

                    <div className="apps_main_container">
                        <div className="apps_container border_box_container">
                            <div className="apps_heading default_title">Our Apps</div>
                            <div className="apps_para default_value">ShipFi is the fusion of AnartraCred and AndarFi, merging decentralized payment capabilities with on-chain functionalities.</div>

                            <div className="apps_content_container">
                                <div className="apps_conetent">
                                    <div className="banner_container">
                                        <div className="left_container">
                                            <div className='text_up'>
                                                <div className="title">Track your progress with our advanced site.</div>
                                                <div className="para">We share common trends and strategies for improving your rental income and making sure you stay in high demand.</div>

                                            </div>
                                            <div className="icon">
                                                <div dangerouslySetInnerHTML={{ __html: product2Logo }} />
                                            </div>
                                        </div>
                                        <div className="right_container">
                                            <img src={App1} alt="Image" />
                                        </div>
                                    </div>

                                    <div className="banner_container banner_container_reverse">
                                        <div className="left_container">
                                            <div className='text_up'>
                                                <div className="title">Track your progress with our advanced site.</div>
                                                <div className="para">We share common trends and strategies for improving your rental income and making sure you stay in high demand.</div>

                                            </div>
                                            <div className="icon">
                                                <div dangerouslySetInnerHTML={{ __html: product1Logo }} />
                                            </div>
                                        </div>
                                        <div className="right_container">
                                            <img src={App2} alt="Image" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Latest News  */}

                    <div className="latest_news_main_container">
                        <div className="latest_news_container border_box_container">
                            <div className="latest_news_heading default_title">Latest News</div>
                            <div className="latest_news_para default_value">Revolutionizing the way you access credit and make payments. Seamlessly tokenize real-world assets for unparalleled financial flexibility and security.</div>

                            <div className="latest_news_content_container">
                                <div className="latest_news_content">

                                    <section className="latest-news">
                                        <div className="articles-grid">
                                            {articles.map((article) => (
                                                <ArticleCard key={article.id} article={article} />
                                            ))}
                                        </div>
                                    </section>


                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Contact Us */}

                    <div className="contactus_main_container">
                        <div className="contactus_container  border_box_container">


                            <main className="contact-page">
                                <div className="contact-container">
                                    {/* <ContactInfo /> */}

                                    <div className="contact-info">
                                        <h2 className="contact-title">Contact Us</h2>
                                        <p className="contact-description">
                                            Need to get in touch with us? Either fill out the form with your inquiry
                                            or mail us at info@comdex.one
                                        </p>
                                    </div>
                                    {/* <ContactForm /> */}
                                    <form className="contact-form">
                                        <div className="form-row" style={{ marginBottom: "30px" }}>
                                            <div className="form-group">
                                                <label htmlFor="firstName" className="form-label">
                                                    First Name
                                                </label>
                                                <input type="text" id="firstName" className="form-input" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="lastName" className="form-label">
                                                    Last Name
                                                </label>
                                                <input type="text" id="lastName" className="form-input" />
                                            </div>
                                        </div>
                                        <div className="form-group" style={{ marginBottom: "30px" }}>
                                            <label htmlFor="email" className="form-label">
                                                Email
                                            </label>
                                            <input type="email" id="email" className="form-input" />
                                        </div>
                                        <div className="form-group" >
                                            <label htmlFor="message" className="form-label">
                                                What can we help you with?
                                            </label>
                                            <textarea id="message" className="form-textarea"></textarea>
                                        </div>
                                        {/* <button type="submit" className="form-submit-btn">
                                            Contact Us
                                        </button> */}

                                        <Button type='primary' className="form-submit-btn">Contact Us</Button>
                                    </form>
                                </div>
                            </main>

                        </div>
                    </div>

                    {/* Footer  */}

                    <Footer />

                </div>
            </div>
        </div>
    )
}

export default HomePage